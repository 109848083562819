import React from 'react';
import { Container,  Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BlurbTab = () => {
  
  // Fetch chart data based on the selected county and property type
 

  return (
    <Container className='chart-container' style={{marginTop: '1rem'}}> 
    <Col>
    <h2>Overview</h2>
    <p>
        There are 2 tabs to explore:
    </p>

    <h4>ML Index</h4>
    <hr/>

    The ML Index tab shows a machine learning-based property index. This is chain-linked, mix adjusted index calculated in a way which is similar to the ONS HPI.
          The key difference is that instead of using weights across the entire market, we use an ensemble of machine learning models to predict the price of a property based on its attributes.
          The attributes can interact with each other in a non-linear way, which is why we believe it is is a more accurate representation of the market.
    <p>
      <Link to="/methodology">Read more about the index</Link>
      </p>

    <h4>Raw Data</h4>
    <hr/>
    <p>
      The Raw Data tab shows the raw data that we use to calculate the index. This is the data that we get from the Land Registry and the ONS. 
      There's nothing fancy here, just the data that we use to calculate the index. The data comes from <a href="https://www.gov.uk/government/statistical-data-sets/price-paid-data-downloads">here</a> and is combined
      with EPC data from the <a href="https://epc.opendatacommunities.org/">Open Data Communities</a> website.
    </p>
    </Col>
    

    </Container>
  );
};

export default BlurbTab;
